import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import sort from '../../../images/settings/sort-icon.svg';
import { ClassName } from '../../general/props/classname';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    width: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const SortButton: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <img src={sort} className={classes.icon} alt="並べ替えボタン" />
    </div>
  );
};
