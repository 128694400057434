import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTOAllergen } from 'models';
import React, { FC } from 'react';
import { AdminIconButton } from '../parts/adminIconButton';
import { AllergenBadge } from '../parts/allergenBadge';

interface Props {
  text: string;
  allergens: DTOAllergen[];
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  headline: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  text: {
    fontSize: '1rem',
    margin: '0.5rem 0',
  },
  iconButton: {
    width: '1rem',
    marginLeft: '2rem',
  },
  allrgenBadeges: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const AllergenList: FC<Props> = ({ text, allergens, onClick }) => {
  const classes = useStyles();

  return (
    <>
      <ButtonBase className={classes.headline} onClick={onClick}>
        <Typography className={classes.text}>{text}</Typography>
        <AdminIconButton className={classes.iconButton} iconType="edit" onClick={onClick} />
      </ButtonBase>
      {allergens.length === 0 ? (
        <Typography variant="body2">設定なし</Typography>
      ) : (
        <div className={classes.allrgenBadeges}>
          {allergens.map((allergen) => (
            <AllergenBadge key={`allergen-${allergen.id}`} allergen={allergen} />
          ))}
        </div>
      )}
    </>
  );
};
