import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext, useMemo } from 'react';
import { AdminMenuContext } from '../../../modules/settings';
import { ClassName } from '../../general/props/classname';
import { AdminButtonGroup } from '../../general/units/adminButtonGroup';
import { AllergenButton } from '../parts/allergenButton';
import { AllergenSettingFormProps } from './editProductForm';
import { OptionalSettingDrawerWrapper } from './optionalSettingDrawerWrapper';

type Props = ClassName & {
  closing: boolean;
  allergenSettingFormProps: AllergenSettingFormProps;
};

const useStyles = makeStyles((theme) => ({
  allergen: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    marginTop: '5%',
  },
  item: {
    marginTop: theme.spacing(3.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  buttonGroup: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    width: '100%',
    zIndex: 4,
  },
  titleWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '2rem',
    boxSizing: 'border-box',
  },
  instructions: { width: '100%', color: theme.palette.text.primary },
}));

/**
 * @param closing trueのときは描画されるがクローズアニメーションが走って消える（右方向に引っ込んでいく）
 */
export const AllergenSettingDrawer: FC<Props> = ({ className, closing, allergenSettingFormProps }) => {
  const classes = useStyles();
  const adminMenuStore = useContext(AdminMenuContext);
  const { allergens } = adminMenuStore.state;

  const { selectedAllergens, setNewAllergens, onSaveAllergens, onCancel } = allergenSettingFormProps;

  const allergensButtonProps = useMemo(() => {
    return allergens.map((allergen) => {
      if (selectedAllergens) {
        const isInclude = selectedAllergens.some((defaultAllergen) => defaultAllergen.id === allergen.id);
        if (isInclude) {
          return { ...allergen, isSelect: true };
        }
      }
      return { ...allergen, isSelect: false };
    });
  }, [selectedAllergens]);

  return (
    <OptionalSettingDrawerWrapper className={className} closing={closing}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} variant="h4">
          アレルゲン
        </Typography>
        <Typography variant="body2" className={classes.instructions}>
          アレルゲンを選択し、【確定】をクリックしてください。
        </Typography>
      </div>
      <div className={classes.allergen}>
        {allergensButtonProps &&
          allergensButtonProps.map((allergenButtonProps) => (
            <AllergenButton
              key={`allergen-${allergenButtonProps.id}`}
              allergen={allergenButtonProps}
              onClick={setNewAllergens}
              className={classes.item}
            />
          ))}
        <div className={classes.buttonGroup}>
          <AdminButtonGroup
            positiveText="確定"
            negativeText="キャンセル"
            onPositiveClick={onSaveAllergens}
            onNegativeClick={onCancel}
            className={classes.buttonGroup}
          />
        </div>
      </div>
    </OptionalSettingDrawerWrapper>
  );
};
