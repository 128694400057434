import { makeStyles, Typography } from '@material-ui/core';
import { DTOCategory } from 'models';
import React, { FC, useContext, useEffect, useState } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { ClassName } from '../../general/props/classname';
import { AdminIconButton } from '../parts/adminIconButton';
import { CategoryNameDialog } from './categoryNameDialog';

type Props = ClassName & {
  onDeleteCategoryClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  categoryNameContents: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryName: {
    fontSize: '1.5rem',
  },
  buttonGroup: {
    display: 'flex',
  },
  iconButton: {
    width: '1.5rem',
    margin: '0 0.5rem',
  },
}));

export const CategoryNameForm: FC<Props> = ({ className, onDeleteCategoryClick }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);
  const uiStore = useContext(UIContext);

  const selectCategory: DTOCategory | undefined = adminMenuStore.state.menus
    .flatMap((menu) => menu.categories.map((category: DTOCategory) => category))
    .find((category) => category.id === adminMenuStore.state.selectingCategoryId);

  const selectCategoryName = selectCategory?.name ? selectCategory.name : '';
  const [categoryName, setCategoryName] = useState(selectCategoryName);
  const [categoryNameErrFlg, setCategoryNameErrFlg] = useState(false);
  const [categoryNameErrMsg, setCategoryNameErrMsg] = useState('');

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = async () => {
    setCategoryNameErrMsg('');
    setOpen(false);
  };

  const validateCategoryName = (value: string): void => {
    if (value.length < 1) {
      setCategoryNameErrFlg(true);
      setCategoryNameErrMsg('カテゴリー名を入力してください');
    } else {
      setCategoryNameErrFlg(false);
    }
  };

  const onCategoryNameChange = (value: string) => {
    validateCategoryName(value);
    if (value.length <= 20) {
      setCategoryName(value);
    }
  };

  const onCategoryNameBlur = (value: string) => {
    validateCategoryName(value);
  };

  useEffect(() => {
    setCategoryName(selectCategoryName);
  }, [open]);

  const onUpdateName = async () => {
    if (selectCategory !== undefined) {
      if (!categoryNameErrFlg) {
        selectCategory.name = categoryName;
        await adminMenuOperations.updateCategory(uiStore.dispatch, adminMenuStore.dispatch, selectCategory);
        onClose();
      }
    }
  };

  return (
    <>
      {selectCategory && (
        <>
          <div className={classes.categoryNameContents}>
            <Typography className={classes.categoryName}>{selectCategory.name}</Typography>
            <div className={classes.buttonGroup}>
              <AdminIconButton className={classes.iconButton} iconType="edit" onClick={onOpen} />
              <AdminIconButton className={classes.iconButton} iconType="delete" onClick={onDeleteCategoryClick} />
            </div>
          </div>
          <CategoryNameDialog
            value={categoryName}
            open={open}
            positiveText="更新する"
            errMsg={{ flg: categoryNameErrFlg, msg: categoryNameErrMsg }}
            onChange={onCategoryNameChange}
            onBlur={onCategoryNameBlur}
            onClose={onClose}
            onPositiveClick={onUpdateName}
          />
        </>
      )}
    </>
  );
};
