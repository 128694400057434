import { DTOProduct } from 'models';
import React, { FC, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { DraggableProduct } from './draggableProduct';

type Props = {
  products: DTOProduct[];
  toggleIsFormOpen: (type: 'create' | 'edit', product?: DTOProduct | undefined) => () => void;
  updateSequence: (
    id: number,
    sequence: {
      sequence: number;
    }
  ) => void;
};

export const DroppableProduct: FC<Props> = ({ products, toggleIsFormOpen, updateSequence }) => {
  const [dndState, setDndState] = useState(products);

  useEffect(() => {
    setDndState(products);
  }, products);

  const reorder = (list: DTOProduct[], startIndex: number, endIndex: number): DTOProduct[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const itemList = reorder(dndState, result.source.index, result.destination.index);
    // destination.indexが最終的な順序の番号
    const id = result.draggableId;
    const sequence = result.destination.index + 1;
    updateSequence(parseInt(id, 10), { sequence });
    setDndState(itemList);
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="menuList">
        {(provided) => (
          <div
            ref={provided.innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
          >
            {dndState.map((product, index) => {
              return (
                <DraggableProduct
                  key={product.id}
                  product={product}
                  index={index}
                  toggleIsFormOpen={toggleIsFormOpen}
                />
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
