import { makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC, useMemo } from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ClassName } from '../../general/props/classname';
import { SelectingOptionsTable } from './selectingOptionsTable';
import 'swiper/swiper-bundle.css';

type selectingOption = DTOOptionGroup & { isSelected: boolean };

type Props = ClassName & {
  selectingOptions: selectingOption[];
};

const useStyles = makeStyles((theme) => ({
  swiper: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
  },
  swiperSlide: { boxSizing: 'border-box', height: '80%', textAlign: 'center', width: '100％' },
  swiperNavigation: { top: '5rem', zIndex: 10 },
  swiperPagination: {
    position: 'absolute',
    top: 0,
    margin: '0 auto',
    color: theme.palette.text.primary,
    marginTop: '1rem',
    fontFamily: theme.typography.fontFamily,
  },
  content: {
    width: '90%',
    margin: 'auto',
    height: '100%',
    boxSizing: 'border-box',
  },
  table: {},
  notable: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));

export const SelectingOptionsCarousel: FC<Props> = ({ className, selectingOptions }) => {
  const classes = useStyles({});
  SwiperCore.use([Navigation, Pagination]);

  const countSelectings = useMemo(
    () => selectingOptions.filter((selectingOption) => selectingOption.isSelected).length,
    [selectingOptions]
  );

  if (countSelectings === 0)
    return (
      <Typography variant="body1" className={classes.notable}>
        選択中のオプションはありません。
      </Typography>
    );

  return (
    <Swiper
      className={`${classes.swiper} ${className}`}
      direction="horizontal"
      pagination={{
        el: '.swiper-pagination',
        type: 'fraction',
      }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
      autoHeight
    >
      {selectingOptions.map(
        (selectingOption, i) =>
          selectingOption.isSelected && (
            <SwiperSlide
              className={classes.swiperSlide}
              key={`selecting-option-${selectingOption.id}`}
              data-hash={`slide${i}`}
            >
              <div className={classes.content}>
                <SelectingOptionsTable className={classes.table} selectingOption={selectingOption} />
              </div>
            </SwiperSlide>
          )
      )}
      <div className={`swiper-pagination ${classes.swiperPagination}`} />
      <div className={`swiper-button-next swiper-button-black ${classes.swiperNavigation}`} />
      <div className={`swiper-button-prev swiper-button-black ${classes.swiperNavigation}`} />
    </Swiper>
  );
};
