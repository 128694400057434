import { makeStyles, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { FC, KeyboardEvent } from 'react';
import { AdminButtonGroup } from '../../general/units/adminButtonGroup';
import { AdminInput } from '../parts/adminInput';
import { SettingsDialog } from '../parts/settingsDialog';

type Props = {
  open: boolean;
  value: string;
  positiveText: string;
  errMsg: { flg: boolean; msg: string };
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  onClose(): void;
  onPositiveClick: () => void;
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  dialogWrapper: {},
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  textFieldWrap: { width: '90%', marginBottom: '40px' },
  textFieldTitle: { marginBottom: '20px' },
  textField: { width: '100%', marginBottom: '10px' },
  errMsgWrapper: { height: '5px' },
  errMsg: { color: 'red', fontSize: '0.8rem' },
  input: { height: '2rem' },
  dialogButtonWrap: { width: '100%' },
}));

export const CategoryNameDialog: FC<Props> = ({
  open,
  value,
  positiveText,
  errMsg,
  onChange,
  onBlur,
  onClose,
  onPositiveClick,
}) => {
  const classes = useStyles();

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onPositiveClick();
    }
  };

  return (
    <SettingsDialog className={classes.dialogWrapper} height="45%" open={open} onClose={onClose}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.textFieldWrap}>
          <Typography className={classes.textFieldTitle} variant="h6">
            カテゴリー名
          </Typography>
          <AdminInput
            className={classes.textField}
            inputClassName={classes.input}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onEnterDown}
          />
          <div className={classes.errMsgWrapper}>{errMsg.flg && <p className={classes.errMsg}>{errMsg.msg}</p>}</div>
        </div>
        <DialogActions className={classes.dialogButtonWrap}>
          <AdminButtonGroup
            positiveText={positiveText}
            negativeText="キャンセル"
            onPositiveClick={onPositiveClick}
            onNegativeClick={onClose}
            isPositiveDisabled={errMsg.flg}
          />
        </DialogActions>
      </DialogContent>
    </SettingsDialog>
  );
};
