import { makeStyles, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import React, { FC } from 'react';
import { IconTextButton } from '../parts/iconTextButton';

type Props = {
  openDndProductList: () => void;
  toggleIsFormOpen: () => void;
  isSortOpen: boolean;
};

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    height: '2rem',
    margin: '2rem 0.5rem 0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '1rem',
    lineHeight: '2rem',
    '@media (max-width: 1024px)': {
      fontSize: '0.9rem',
    },
  },
  button: {},
  sortButton: {
    marginLeft: 'auto',
  },
  sorting: {
    background: '#468858',
    '&:hover': {
      background: '#468858',
    },
  },
  addButton: {
    marginLeft: '1rem',
  },
  icon: {
    marginRight: '5px',
    '@media (max-width: 1024px)': {
      fontSize: '20px',
    },
  },
}));

export const ProductListHeaderTitle: FC<Props> = ({ openDndProductList, toggleIsFormOpen, isSortOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.titleWrapper}>
      <Typography className={classes.title}>商品一覧</Typography>
      <IconTextButton
        className={`${classes.button} ${classes.sortButton} ${isSortOpen && classes.sorting}`}
        icon={<FormatListNumbered className={classes.icon} />}
        text={isSortOpen ? '並べ替えを終了' : '商品を並べ替え'}
        onClick={openDndProductList}
      />
      <IconTextButton
        className={`${classes.button} ${classes.addButton}`}
        icon={<AddCircleOutlineIcon className={classes.icon} />}
        text="商品を追加"
        onClick={toggleIsFormOpen}
      />
    </div>
  );
};
