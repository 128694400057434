import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  border: {
    /* 親要素のwidthで長さを調節 */
    height: '1px',
    backgroundColor: '#cbcbcb',
  },
}));

export const AdminBorder: FC<Props> = ({ className }) => {
  const classes = useStyles();
  return <div className={`${classes.border} ${className}`} />;
};
