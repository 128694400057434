import { makeStyles, Typography } from '@material-ui/core';
import { DTOCategory } from 'models';
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { SortButton } from '../parts/sortButton';

type Props = {
  category: DTOCategory;
  index: number;
  onClickFactory: (categoryId: number) => () => void;
  selected: boolean;
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    padding: '0.4rem',
    color: theme.palette.text.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderBottom: '1px solid #fff',
    '&:hover': {
      color: '#6b6b6b',
      backgroundColor: theme.sideMenu?.hover.background,
    },
    '&:active': {
      backgroundColor: theme.sideMenu?.hover.background,
      borderBottom: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  selectedTitle: {
    backgroundColor: theme.sideMenu?.hover.background,
  },
  sortButton: {
    paddingLeft: '1rem',
  },
}));

export const DraggableCategory: FC<Props> = ({ category, index, onClickFactory, selected }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={`${category.id}`} index={index} key={`category-${category.id}`}>
      {(provide) => (
        <div
          ref={provide.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provide.dragHandleProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provide.draggableProps}
          tabIndex={-1}
        >
          {/* NOTE:react-beautiful-dnd がButtonBaseだと正常に動作しないためdivにroleを付けている */}
          <div
            role="button"
            className={`${classes.title} ${selected && classes.selectedTitle}`}
            tabIndex={0}
            onClick={onClickFactory(category.id)}
            onKeyDown={onClickFactory(category.id)}
          >
            <Typography>{category.name}</Typography>
            <SortButton className={classes.sortButton} />
          </div>
        </div>
      )}
    </Draggable>
  );
};
