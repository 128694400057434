import { makeStyles } from '@material-ui/core';
import { DTOProduct } from 'models';
import React, { FC } from 'react';
import { DroppableProduct } from './droppableProduct';
import { ProductInfo } from './productInfo';

type Props = {
  products: DTOProduct[];
  isSortOpen: boolean;
  toggleIsFormOpen: (type: 'create' | 'edit', product?: DTOProduct) => () => void;
  updateSequence: (id: number, sequence: { sequence: number }) => void;
};

const useStyles = makeStyles((theme) => ({
  productListWrapper: {
    height: 'calc(100vh - 12rem)',
    paddingBottom: '1rem',
    overflowY: 'scroll',
  },
}));

export const ProductInfoList: FC<Props> = ({ products, isSortOpen, toggleIsFormOpen, updateSequence }) => {
  const classes = useStyles();

  return (
    <div className={classes.productListWrapper}>
      {isSortOpen ? (
        <DroppableProduct products={products} toggleIsFormOpen={toggleIsFormOpen} updateSequence={updateSequence} />
      ) : (
        products.map((product) => {
          return <ProductInfo key={product.id} product={product} onClick={toggleIsFormOpen('edit', product)} />;
        })
      )}
    </div>
  );
};
