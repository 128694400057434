import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';
import { SelectingOptionsTableRow } from './selectingOptionsTableRow';

type Props = ClassName & {
  selectingOption: DTOOptionGroup;
};

const useStyles = makeStyles((theme) => ({
  table: {},
  title: { margin: '1rem auto', paddingTop: '3rem', width: '80%' },
  body: { marginTop: '2rem' },
  head: {
    padding: '1rem',
    textAlign: 'left',
    color: theme.palette.text.primary,
    background: '#ededed',
  },
  headLeft: {
    borderRadius: '0.5rem 0 0 0',
  },
  headRight: {
    borderRadius: '0 0.5rem 0 0',
  },
  row: {},
  bottom: {
    borderRadius: '0 0 1rem 1rem',
    color: theme.palette.text.primary,
    marginBottom: '2rem',
    textAlign: 'left',
    padding: '1rem',
    position: 'relative',
    zIndex: 2,
  },
}));

export const SelectingOptionsTable: FC<Props> = ({ className, selectingOption }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.table} ${className}`}>
      <Typography variant="h5" className={classes.title}>
        {selectingOption.name}
      </Typography>
      <div className={classes.body}>
        <Grid container spacing={0}>
          <Grid item xs={7}>
            <Paper className={`${classes.head} ${classes.headLeft}`}>オプション項目</Paper>
          </Grid>
          <Grid item xs={5}>
            <Paper className={`${classes.head} ${classes.headRight}`}>価格(税抜)</Paper>
          </Grid>
        </Grid>
        {selectingOption.options.map((option) => (
          <SelectingOptionsTableRow
            key={`selecting-option-optionItem-${option.id}`}
            className={classes.row}
            option={option}
          />
        ))}
        <Grid item xs={12}>
          <Paper className={classes.bottom}>
            <Typography variant="body2">{`● 注文数の上限：${selectingOption.maxSelectionCount} 点`}</Typography>
          </Paper>
        </Grid>
      </div>
    </div>
  );
};
