import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { MenuLayout } from '../../../components/settings/layouts/menuLayout';
import { menuTheme } from '../../../components/settings/theme/menuTheme';
import { settingsTheme } from '../../../components/settings/theme/settingsTheme';
import { TaxContextProvider } from '../../../modules/general/tax';
import { UIContextProvider } from '../../../modules/general/ui';
import { AdminMenuContextProvider } from '../../../modules/settings';
import { DesignContextProvider } from '../../../modules/settings/design';
import { MenuContextProvider } from '../../../modules/settings/menu';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const SettingsMenuPage: FC = () => {
  const classes = useStyles();

  return (
    <AdminMenuContextProvider>
      <DesignContextProvider>
        <TaxContextProvider>
          <MenuContextProvider>
            <UIContextProvider>
              <ThemeProvider theme={menuTheme}>
                <ThemeProvider theme={settingsTheme}>
                  <div className={classes.wrapper}>
                    <MenuLayout />
                  </div>
                </ThemeProvider>
              </ThemeProvider>
            </UIContextProvider>
          </MenuContextProvider>
        </TaxContextProvider>
      </DesignContextProvider>
    </AdminMenuContextProvider>
  );
};

export default SettingsMenuPage;
