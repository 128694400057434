import React, { FC, useContext, useState } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { CategoryNameDialog } from './categoryNameDialog';

type Props = {
  menuId: number;
  open: boolean;
  onClose: () => void;
};

export const AddCategoryForm: FC<Props> = ({ menuId, open, onClose }) => {
  const adminMenuStore = useContext(AdminMenuContext);
  const uiStore = useContext(UIContext);

  const [categoryName, setCategoryName] = useState('');
  const [categoryNameErrFlg, setCategoryNameErrFlg] = useState(true);
  const [categoryNameErrMsg, setCategoryNameErrMsg] = useState('');

  const validateCategoryName = (value: string): void => {
    if (value.length < 1) {
      setCategoryNameErrFlg(true);
      setCategoryNameErrMsg('カテゴリー名を入力してください');
    } else {
      setCategoryNameErrFlg(false);
    }
  };

  const onCategoryNameChange = (value: string) => {
    validateCategoryName(value);
    if (value.length <= 20) {
      setCategoryName(value);
    }
  };

  const onCategoryNameBlur = (value: string) => {
    validateCategoryName(value);
  };

  const handleClose = () => {
    onClose();
    setCategoryNameErrMsg('');
  };

  const onAddCategory = async () => {
    if (!categoryNameErrFlg) {
      await adminMenuOperations.createCategory(uiStore.dispatch, adminMenuStore.dispatch, {
        name: categoryName,
        menuId,
      });

      setCategoryName('');
      setCategoryNameErrFlg(true);
      onClose();
    } else {
      validateCategoryName(categoryName);
    }
  };

  return (
    <CategoryNameDialog
      value={categoryName}
      open={open}
      positiveText="新規追加"
      errMsg={{ flg: categoryNameErrFlg, msg: categoryNameErrMsg }}
      onChange={onCategoryNameChange}
      onBlur={onCategoryNameBlur}
      onClose={handleClose}
      onPositiveClick={onAddCategory}
    />
  );
};
