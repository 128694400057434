import { makeStyles, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { FC, useContext, useState } from 'react';
import { UIContext as AdminUIContext, UIContext } from '../../../modules/general/ui';
import { adminMenuActions, AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { menuActions, MenuContext, menuOperations } from '../../../modules/settings/menu';
import { ClassName } from '../../general/props/classname';
import { AddCategoryForm } from './addCategoryForm';
import { CategoryList } from './categoryList';

type Props = ClassName & {
  closeForm: () => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    backgroundColor: theme.sideMenu?.background,
    borderRight: '1px solid #cbcbcb',
    overflowY: 'auto',
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    color: '#ffffff',
    backgroundColor: '#9dd2ac',
  },
  menuName: {
    textAlign: 'left',
    padding: '0.6rem',
  },
  icon: {
    marginRight: '0.6rem',
    cursor: 'pointer',
  },
}));

export const CategorySideMenu: FC<Props> = ({ className, closeForm }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);
  const adminUiStore = useContext(AdminUIContext);
  const menuStore = useContext(MenuContext);

  const dispatchCategoryClicked = (categoryId: number) => {
    adminMenuStore.dispatch(adminMenuActions.categoryClicked(categoryId));
    menuStore.dispatch(menuActions.categoryTabClicked(categoryId));
    closeForm();
  };

  const updateSequence = async (categoryId: number, sequence: number, menuId: number) => {
    await adminMenuOperations.updateCategorySequence(adminUiStore.dispatch, adminMenuStore.dispatch, categoryId, {
      sequence,
      menuId,
    });
  };

  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {adminMenuStore.state.menus.map((menu) => (
        <div key={`menu-${menu.id}`}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.menuName}>{menu.name}</Typography>
            <AddCircleOutlineIcon className={classes.icon} onClick={onOpen} />
          </div>
          <CategoryList
            menu={menu}
            updateSequence={updateSequence}
            onCategoryClick={dispatchCategoryClicked}
            onOpen={onOpen}
          />
          <AddCategoryForm menuId={menu.id} open={open} onClose={onClose} />
        </div>
      ))}
    </div>
  );
};
