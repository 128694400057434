import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { DTOOption } from 'models';
import React, { FC } from 'react';
import { Yen } from '../../general/parts/Yen';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  option: DTOOption;
};

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    width: '100%',
  },
  column: {
    height: '100%',
  },
  cell: {
    alignItems: 'center',
    borderRadius: '0',
    color: theme.palette.text.primary,
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    position: 'relative',
    textAlign: 'left',
    zIndex: 0,
  },
  name: {
    padding: '1rem',
  },
  price: {
    padding: '1rem',
  },
}));

export const SelectingOptionsTableRow: FC<Props> = ({ className, option }) => {
  const classes = useStyles();

  return (
    <Grid container className={className}>
      <Grid container xs={12} className={classes.row}>
        <Grid item xs={7} className={classes.column}>
          <Paper className={classes.cell}>
            <Typography variant="body1" className={classes.name}>
              {option.name}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={5} className={classes.column}>
          <Paper className={classes.cell}>
            <Yen price={option.price} variant="body1" className={classes.price} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
