import { format } from 'date-fns';
import { DTOProduct } from 'models';
import React, { FC } from 'react';
import noImageImg from '../../../images/settings/image-white.svg';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  product: DTOProduct;
};

export const ProductImage: FC<Props> = ({ className, product }) => {
  return (
    <img
      src={
        product.hasImage
          ? `/images/products/${product.id}.png?date=${format(new Date(product.updatedAt), 'yyyyMMddHHmmss')}`
          : noImageImg
      }
      className={className}
      alt={`${product.name}の写真`}
    />
  );
};
