import { makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { FC } from 'react';
import { IconTextButton } from '../parts/iconTextButton';

type Props = {
  onClick(): void;
};

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
  },
  icon: {
    marginRight: '5px',
  },
}));

export const AddCategoryButton: FC<Props> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.iconButton}>
        <IconTextButton
          icon={<AddCircleOutlineIcon className={classes.icon} />}
          text="カテゴリーを追加"
          onClick={onClick}
        />
      </div>
    </>
  );
};
