import { DTOCategory } from 'models';
import React, { FC, useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { AdminMenuContext } from '../../../modules/settings';
import { getCategoriesAsSideMenuProps } from '../../../modules/settings/selectors';
import { DraggableCategory } from './draggableCategory';

type Props = {
  categories: DTOCategory[];
  menuId: number;
  onClickFactory: (categoryId: number) => () => void;
  updateSequence: (categoryId: number, sequence: number, menuId: number) => void;
};

type SideMenuProps = DTOCategory & { selected: boolean };

export const DroppableCategory: FC<Props> = ({ categories, menuId, onClickFactory, updateSequence }) => {
  const adminMenuStore = useContext(AdminMenuContext);
  const [dndState, setDndState] = useState(getCategoriesAsSideMenuProps(categories, adminMenuStore.state));

  useEffect(() => {
    setDndState(getCategoriesAsSideMenuProps(categories, adminMenuStore.state));
  }, [categories, adminMenuStore.state.selectingCategoryId]);

  const reorder = (list: SideMenuProps[], startIndex: number, endIndex: number): SideMenuProps[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const itemList = reorder(dndState, result.source.index, result.destination.index);
    const categoryId = result.draggableId;
    const sequence = result.destination.index + 1;
    updateSequence(parseInt(categoryId, 10), sequence, menuId);

    setDndState(itemList);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="menuList">
        {(provided) => (
          <div
            ref={provided.innerRef}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
          >
            {dndState.map((category, index) => (
              <DraggableCategory
                category={category}
                index={index}
                onClickFactory={onClickFactory}
                key={`category-${category.id}`}
                selected={category.selected}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
