import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC, useState } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  optionGroup: DTOOptionGroup & { isSelected: boolean };
  onClick: (selectedOptionGroup: DTOOptionGroup) => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.secondary.main,
    borderRadius: '2rem',
    minWidth: '6rem',
    height: '2rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: 'center',
  },
  item: { color: theme.palette.text.primary },
  selected: {
    background: theme.settingsItemButton?.background,
    color: '#ffffff',
  },
}));

export const OptionGroupButton: FC<Props> = ({ className, optionGroup, onClick }) => {
  const classes = useStyles();
  const [isSelected, setIsSelected] = useState(optionGroup.isSelected);
  const handleClick = () => {
    setIsSelected(!isSelected);
    onClick(optionGroup);
  };

  return (
    <>
      <ButtonBase className={`${classes.wrapper} ${isSelected && classes.selected} ${className}`} onClick={handleClick}>
        <Typography className={`${classes.item} ${isSelected && classes.selected}`} variant="body2">
          {optionGroup.name}
        </Typography>
      </ButtonBase>
    </>
  );
};
