import { makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  optionGroup: DTOOptionGroup;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.settingsItemButton?.background,
    borderRadius: '2rem',
    minWidth: '3rem',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: '0.5rem 0.3rem',
    textAlign: 'center',
  },
  item: { color: '#ffffff' },
}));

export const OptionGroupBadge: FC<Props> = ({ className, optionGroup }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper}  ${className}`}>
      <Typography className={classes.item} variant="body1">
        {optionGroup.name}
      </Typography>
    </div>
  );
};
