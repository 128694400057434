import { makeStyles } from '@material-ui/core';
import { DTOAllergen, DTOOptionGroup } from 'models';
import React, { FC, KeyboardEvent, useContext, useMemo, useState } from 'react';
import noImageImg from '../../../images/settings/image-gray.svg';
import { AdminMenuContext } from '../../../modules/settings';
import { findNextTabStop } from '../../../utils/findNextTabStop';
import { AdminButtonGroup } from '../../general/units/adminButtonGroup';
import { AdminInput } from '../parts/adminInput';
import { TakeoutToggleButton } from '../parts/takeoutButton';
import { AdminImageInputWithCrop } from './adminImageInputWithCrop';
import { AllergenList } from './allergenList';
import { AllergenSettingDrawer } from './allergenSettingDrawer';
import { OptionList } from './optionList';
import { OptionSettingDrawer } from './optionSettingDrawer';

export interface AllergenSettingFormProps {
  selectedAllergens: DTOAllergen[];
  setNewAllergens: (selectedId: number, selectedName: string) => void;
  onSaveAllergens: () => void;
  onCancel: () => void;
}

export interface OptionFormProps {
  type: 'required' | 'optional';
  selectedSelectables: DTOOptionGroup[];
  setNewSelectables: (selectedSelectable: DTOOptionGroup) => void;
  onSaveSelectables: () => void;
  onCancel: () => void;
}

export interface EditProductFormValues {
  name: string;
  price: string;
  description: string;
  allergens: DTOAllergen[];
  selectables: DTOOptionGroup[];
  image: {
    hasImage: boolean;
    data: string;
  };
  takeoutEnabled: boolean;
  soldoutEndDate?: string;
}

interface Props {
  defaults: EditProductFormValues;
  onSave: (values: EditProductFormValues) => void;
  onCancel?: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: theme.settingsDrawer?.width,
    backgroundColor: theme.settingsDrawer?.background,
    padding: '0 2rem',
    overflowY: 'auto',
    position: 'absolute',
    zIndex: 2,
    right: '0',
    animation: '$slideIn  0.2s ease-out',
    '@media (min-width: 2000px)': {
      width: '44vw',
    },
  },
  '@keyframes slideIn': {
    from: {
      opacity: 0,
      transform: 'translateX(150px)',
    },
    to: {
      opacity: 1,
      transform: 'translateX(0px)',
    },
  },
  categoryName: {
    width: '90%',
    margin: '1rem auto 0',
    fontSize: '2.5rem',
  },
  productNameWrapper: {
    margin: '2rem auto 0.3rem',
    width: '100%',
  },
  productNameInput: {
    fontSize: '1.2rem',
  },
  priceWrapper: {
    margin: '0 0 0.3rem',
  },
  priceInput: {
    fontSize: '1rem',
    width: '10rem',
  },
  errMsgWrapper: {
    height: '1rem',
    marginBottom: '1rem',
  },
  errMsg: {
    color: 'red',
    fontSize: '0.8rem',
  },
  imageWrapper: {
    width: '100%',
  },
  descriptionWrapper: {
    margin: '1.5rem auto 0',
    width: '100%',
  },
  descriptionInput: {
    fontSize: '1rem',
  },
  headline: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  text: {
    fontSize: '1.25rem',
    margin: '0.5rem 0',
  },
  iconButton: {
    width: '1.25rem',
    marginLeft: '2rem',
  },
  buttonGroup: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  shadow: {
    height: '100%',
    width: 'calc(100% - 18.5rem)',
    backgroundColor: '#00000080',
    position: 'absolute',
    zIndex: 3,
    left: '18.5rem',
    animation: '$bgColor 0.5s ease-in-out',
  },
  '@keyframes bgColor': {
    '0%': { backgroundColor: '#ffffff', zIndex: 2 },
    '60%': { zIndex: 3 },
    '100%': { backgroundColor: '#00000080' },
  },
  subDrawerOpen: {
    left: '18.5rem',
    animation: '$optionSlideIn  0.3s ease-out',
  },
  '@keyframes optionSlideIn': {
    from: {
      transform: 'translateX(calc(55vw - 18.5rem))',
    },
    to: {
      transform: 'translateX(0px)',
    },
  },
  subDrawerClose: {
    animation: '$optionSlideOut   0.5s ease-out',
  },
  '@keyframes optionSlideOut': {
    from: {
      transform: 'translateX(-40vw)',
    },
    to: {
      transform: 'translateX(0)',
    },
  },
  iconSideMenuUnfold: {
    width: 'calc(100% - 28.5rem)',
    left: '28.5rem',
    '@media (max-width: 1024px)': {
      left: '25.5rem',
    },
  },
  iconSideMenuFold: {
    left: '18.5rem',
    '@media  (max-width: 1024px)': {
      left: '15.5rem',
    },
  },
  sideMenuUnfold: {
    width: 'calc(40vw - 4rem)',
    left: '28.5rem',
    '@media  (max-width: 1024px)': {
      left: '25.5rem',
    },
  },
}));

export const EditProductForm: FC<Props> = ({ defaults, onSave, onCancel }) => {
  const classes = useStyles();
  const adminMenuStore = useContext(AdminMenuContext);
  const [iconSideMenufold, setIconSideMenuFold] = useState(adminMenuStore.state.toggleFolding);
  const [allergenOpening, setAllergenOpening] = useState(false);
  const [allergenClosing, setAllergenClosing] = useState(false);
  const [requiredOptionOpening, setRequiredOptionOpening] = useState(false);
  const [requiredOptionClosing, setRequiredOptionClosing] = useState(false);
  const [optionalOptionOpening, setOptionalOptionOpening] = useState(false);
  const [optionalOptionClosing, setOptionalOptionClosing] = useState(false);

  const [name, setName] = useState(defaults.name);
  const [price, setPrice] = useState(defaults.price);
  const [description, setDescription] = useState(defaults.description);
  const [allergens, setAllergens] = useState(defaults.allergens);
  const [selectedAllergens, setSelectedAllergens] = useState<DTOAllergen[]>(allergens);
  const [selectables, setSelectables] = useState(defaults.selectables);
  const [selectedSelectables, setSelectedSelectables] = useState<DTOOptionGroup[]>(selectables);
  const [takeoutEnabled, setTakeoutEnabled] = useState(defaults.takeoutEnabled);

  const [image, setImage] = useState(defaults.image);

  const fireAllergenOpenAnimation = () => {
    setAllergenOpening(true);
    setAllergenClosing(false);
  };

  const fireAllergenCloseAnimation = () => {
    setAllergenOpening(false);
    setAllergenClosing(true);
  };

  const fireRequiredOptionOpenAnimation = () => {
    setRequiredOptionOpening(true);
    setRequiredOptionClosing(false);
  };

  const fireRequiredOptionCloseAnimation = () => {
    setRequiredOptionOpening(false);
    setRequiredOptionClosing(true);
  };

  const fireOptionalOptionOpenAnimation = () => {
    setOptionalOptionOpening(true);
    setOptionalOptionClosing(false);
  };

  const fireOptionalOptionCloseAnimation = () => {
    setOptionalOptionOpening(false);
    setOptionalOptionClosing(true);
  };

  const setNewAllergens = (selectedId: number, selectedName: string) => {
    const newSelectedAllergen = { id: selectedId, name: selectedName };
    const isInclude = selectedAllergens.some((selectedAllergen) => selectedAllergen.id === newSelectedAllergen.id);
    if (isInclude) {
      setSelectedAllergens((prev) => prev.filter((allergen) => allergen.id !== newSelectedAllergen.id));
    } else {
      setSelectedAllergens((prev) => [...prev, newSelectedAllergen]);
    }
  };

  const onCancelSelectingAllergens = async () => {
    await setSelectedAllergens(allergens);
    fireAllergenCloseAnimation();
  };

  const onSaveAllergens = () => {
    setAllergens(selectedAllergens);
    fireAllergenCloseAnimation();
  };

  const setNewSelectables = (selectedSelectable: DTOOptionGroup) => {
    const newSelectedSelectable = selectedSelectable;
    const isInclude = selectedSelectables.some((selectable) => selectable.id === newSelectedSelectable.id);
    if (isInclude) {
      setSelectedSelectables((prev) => prev.filter((selectable) => selectable.id !== newSelectedSelectable.id));
    } else {
      setSelectedSelectables((prev) => [...prev, newSelectedSelectable]);
    }
  };

  const onCancelSelectingRequiredSelectables = async () => {
    await setSelectedSelectables(selectables);
    fireRequiredOptionCloseAnimation();
  };

  const onCancelSelectingOptionalSelectables = async () => {
    await setSelectedSelectables(selectables);
    fireOptionalOptionCloseAnimation();
  };

  const onSaveRequiredSelectables = () => {
    setSelectables(selectedSelectables);
    fireRequiredOptionCloseAnimation();
  };

  const onSaveOptionalSelectables = () => {
    setSelectables(selectedSelectables);
    fireOptionalOptionCloseAnimation();
  };

  const [nameErrMsg, setNameErrMsg] = useState('');

  const handleNameChange = (value: string) => {
    if (value.length <= 25) {
      setName(value);
      setNameErrMsg('');
    }
    if (value.length === 0) {
      setNameErrMsg('商品名を入力してください');
    }
  };

  const [priceErrMsg, setPriceErrMsg] = useState('');

  const handlePriceChange = (value: string) => {
    const parsedPrice = parseInt(value, 10);
    if (parsedPrice.toString().length <= 9) {
      setPrice(parsedPrice.toString());
      setPriceErrMsg('');
    }
    if (Number.isNaN(parsedPrice)) {
      setPriceErrMsg('価格を入力してください');
    }
  };

  const handleDescriptionChange = (value: string) => {
    if (value.length <= 300) {
      setDescription(value);
    }
  };

  const handleImageChange = (base64Image: string) => {
    setImage({
      hasImage: true,
      data: base64Image,
    });
  };

  const handleImageDelete = () => {
    setImage({
      hasImage: false,
      data: noImageImg,
    });
  };

  const handleSaveClick = () => {
    if (name.length !== 0 && price.length !== 0) {
      onSave({
        name,
        price,
        image,
        description,
        allergens,
        selectables,
        takeoutEnabled,
      });
    } else {
      if (name.length === 0) {
        setNameErrMsg('商品名を入力してください');
      }
      if (price.length === 0) {
        setPriceErrMsg('価格を入力してください');
      }
    }
  };

  const switchClassName = () => {
    if (allergenOpening || requiredOptionOpening || optionalOptionOpening) {
      return classes.subDrawerOpen;
    }
    if (
      (!allergenOpening && allergenClosing) ||
      (!requiredOptionOpening && requiredOptionClosing) ||
      (!optionalOptionOpening && optionalOptionClosing)
    ) {
      return classes.subDrawerClose;
    }
    return classes.wrapper;
  };
  const selectedClassName = switchClassName();

  const toggleIconSideMenuFolding = () => {
    if (selectedClassName === classes.wrapper) {
      return classes.wrapper;
    }
    if (selectedClassName === classes.subDrawerClose) {
      return classes.wrapper;
    }
    if (!iconSideMenufold) {
      return classes.iconSideMenuUnfold;
    }

    return classes.iconSideMenuFold;
  };
  const iconSideMenuType = toggleIconSideMenuFolding();

  const toggleSideMenuFolding = () => {
    if (selectedClassName === classes.wrapper) {
      return classes.wrapper;
    }
    if (selectedClassName === classes.subDrawerClose) {
      return classes.wrapper;
    }
    if (!iconSideMenufold) {
      return classes.sideMenuUnfold;
    }

    return classes.iconSideMenuFold;
  };
  const sideMenuType = toggleSideMenuFolding();

  const allergenSettingFormProps: AllergenSettingFormProps = {
    selectedAllergens,
    setNewAllergens: (selectedId: number, selectedName: string) => setNewAllergens(selectedId, selectedName),
    onSaveAllergens,
    onCancel: onCancelSelectingAllergens,
  };

  const requiredOptionSettingFormProps: OptionFormProps = {
    type: 'required',
    selectedSelectables,
    setNewSelectables: (selectedSelectable: DTOOptionGroup) => setNewSelectables(selectedSelectable),
    onSaveSelectables: onSaveRequiredSelectables,
    onCancel: onCancelSelectingRequiredSelectables,
  };

  const optionalOptionSettingFormProps: OptionFormProps = {
    type: 'optional',
    selectedSelectables,
    setNewSelectables: (selectedSelectable: DTOOptionGroup) => setNewSelectables(selectedSelectable),
    onSaveSelectables: onSaveOptionalSelectables,
    onCancel: onCancelSelectingOptionalSelectables,
  };

  useMemo(() => {
    toggleIconSideMenuFolding();
    setIconSideMenuFold(adminMenuStore.state.toggleFolding);
  }, [adminMenuStore.state.toggleFolding]);

  const onEnterDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      findNextTabStop(e.target).focus();
    }
  };

  return (
    <>
      <div>
        {allergenOpening && (
          <div className={`${classes.shadow} ${iconSideMenuType}`} onClick={onCancelSelectingAllergens} aria-hidden />
        )}
        {requiredOptionOpening && (
          <div
            className={`${classes.shadow} ${iconSideMenuType}`}
            onClick={onCancelSelectingRequiredSelectables}
            aria-hidden
          />
        )}
        {optionalOptionOpening && (
          <div
            className={`${classes.shadow} ${iconSideMenuType}`}
            onClick={onCancelSelectingOptionalSelectables}
            aria-hidden
          />
        )}
        <div className={`${classes.wrapper} ${selectedClassName} ${sideMenuType} `}>
          <AdminInput
            value={name}
            className={classes.productNameWrapper}
            inputClassName={classes.productNameInput}
            label="商品名"
            onChange={handleNameChange}
            onKeyDown={onEnterDown}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{nameErrMsg}</p>
          </div>
          <AdminInput
            type="number"
            min={0}
            value={price}
            className={classes.priceWrapper}
            inputClassName={classes.priceInput}
            isCurrency={!!price}
            label="価格(税抜)"
            onChange={handlePriceChange}
          />
          <div className={classes.errMsgWrapper}>
            <p className={classes.errMsg}>{priceErrMsg}</p>
          </div>
          <div className={classes.imageWrapper}>
            <AdminImageInputWithCrop
              alt="商品画像"
              src={image.data}
              width={300}
              onCropComplete={handleImageChange}
              onDeleteClick={handleImageDelete}
            />
          </div>
          <AdminInput
            value={description}
            className={classes.descriptionWrapper}
            inputClassName={classes.descriptionInput}
            label="説明文"
            placeholder="説明文を入力してください（300文字まで）"
            rows={8}
            onChange={handleDescriptionChange}
          />
          <AllergenList text="アレルゲン" allergens={allergens} onClick={fireAllergenOpenAnimation} />
          <OptionList type="required" optionGroups={selectables} onClick={fireRequiredOptionOpenAnimation} />
          <OptionList type="optional" optionGroups={selectables} onClick={fireOptionalOptionOpenAnimation} />
          <TakeoutToggleButton takeoutEnabled={takeoutEnabled} onChange={setTakeoutEnabled} />
          <AdminButtonGroup
            className={classes.buttonGroup}
            positiveText="登録"
            negativeText="キャンセル"
            onPositiveClick={handleSaveClick}
            onNegativeClick={onCancel}
            isPositiveDisabled={nameErrMsg !== '' || priceErrMsg !== ''}
          />
        </div>
      </div>
      {(allergenOpening || allergenClosing) && (
        <AllergenSettingDrawer allergenSettingFormProps={allergenSettingFormProps} closing={allergenClosing} />
      )}
      {(requiredOptionOpening || requiredOptionClosing) && (
        <OptionSettingDrawer optionSettingFormProps={requiredOptionSettingFormProps} closing={requiredOptionClosing} />
      )}
      {(optionalOptionOpening || optionalOptionClosing) && (
        <OptionSettingDrawer optionSettingFormProps={optionalOptionSettingFormProps} closing={optionalOptionClosing} />
      )}
    </>
  );
};
