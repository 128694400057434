import { makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { DTOProduct } from 'models';
import React, { FC, useContext, useState } from 'react';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { ConfirmDialog } from './confirmDialog';
import { ProductImage } from './productImage';
import { ProductInfoIconButtons } from './productInfoIconButtons';
import { ProductInfoText } from './productInfoText';

type Props = {
  product: DTOProduct;
  onClick: () => void;
  sorting?: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: (props: { sorting: boolean }) => ({
    display: 'flex',
    width: 'calc((100% + 18px) * 0.9)',
    padding: '1rem 0',
    margin: '0 auto',
    borderBottom: 'solid 1px #cbcbcb',
    '&:hover': {
      cursor: props.sorting ? 'grab' : 'pointer',
      backgroundColor: props.sorting ? '' : '#ededed',
    },
    '&:active': {
      cursor: props.sorting ? 'grabbing' : 'pointer',
      backgroundColor: props.sorting ? '#ffffff' : '#ededed',
      borderBottom: props.sorting ? 'none' : 'solid 1px #cbcbcb',
      boxShadow: props.sorting ? '5px 5px 10px rgba(0,0,0,0.2)' : '',
    },
  }),
  imgAndInfo: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '75%',
  },
  img: {
    width: '4.5rem',
    margin: 'auto 1.5rem',
  },
  invisibleImg: { opacity: 0.4 },
  hiddenBlor: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1,
  },

  whitespace: {
    flexGrow: 1,
  },
  sortingIcon: {
    width: '2rem',
    height: '2rem',
  },
}));

/**
 * 商品一覧の１行
 * @param onClick ボタンやチェックボックス以外の部分をクリックしたときのコールバック
 * @param sorting trueのときすべてのonClickがdisabledになり並べ替えアイコンを表示する
 */
export const ProductInfo: FC<Props> = ({ product, onClick, sorting = false }) => {
  const classes = useStyles({ sorting });

  const uiStore = useContext(UIContext);
  const adminMenuStore = useContext(AdminMenuContext);

  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);

  const openDeleteConfirmDialog = () => {
    setIsOpenDeleteConfirmDialog(true);
  };

  const closeDeleteConfirmDialog = () => {
    setIsOpenDeleteConfirmDialog(false);
  };

  const deleteProduct = async () => {
    await adminMenuOperations.deleteProduct(uiStore.dispatch, adminMenuStore.dispatch, { productId: product.id });
  };

  const hideProduct = async () => {
    await adminMenuOperations.hideProduct(uiStore.dispatch, adminMenuStore.dispatch, { productId: product.id });
  };

  const showProduct = async () => {
    await adminMenuOperations.showProduct(uiStore.dispatch, adminMenuStore.dispatch, { productId: product.id });
  };

  return sorting ? (
    <div className={classes.wrapper}>
      <div className={classes.imgAndInfo}>
        <ProductImage className={`${classes.img} ${product.isHidden && classes.invisibleImg}`} product={product} />
        <ProductInfoText product={product} />
        <span className={classes.whitespace} />
        <MenuIcon className={classes.sortingIcon} />
      </div>
    </div>
  ) : (
    <>
      <div className={classes.wrapper}>
        <div className={classes.imgAndInfo} onClick={onClick} aria-hidden>
          <ProductImage className={`${classes.img} ${product.isHidden && classes.invisibleImg}`} product={product} />
          <ProductInfoText product={product} />
        </div>
        <ProductInfoIconButtons
          product={product}
          onDelete={openDeleteConfirmDialog}
          onHide={hideProduct}
          onShow={showProduct}
        />
      </div>
      {isOpenDeleteConfirmDialog && (
        <ConfirmDialog
          messages={[`${product.name} を削除します`]}
          open={isOpenDeleteConfirmDialog}
          onConfirm={deleteProduct}
          onClose={closeDeleteConfirmDialog}
        />
      )}
    </>
  );
};
