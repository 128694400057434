import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext, useMemo } from 'react';
import { AdminMenuContext } from '../../../modules/settings';
import { getSelectingOptionalOptions, getSelectingRequiredOptions } from '../../../modules/settings/selectors';
import { ClassName } from '../../general/props/classname';
import { AdminButtonGroup } from '../../general/units/adminButtonGroup';
import { OptionGroupButton } from '../parts/optionGroupButton';
import { OptionFormProps } from './editProductForm';
import { OptionalSettingDrawerWrapper } from './optionalSettingDrawerWrapper';
import { SelectingOptionsCarousel } from './selectingOptionsCarousel';

type Props = ClassName & {
  closing: boolean;
  optionSettingFormProps: OptionFormProps;
};

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '2rem',
    boxSizing: 'border-box',
  },
  instructions: { width: '100%', color: theme.palette.text.primary },
  section: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: '1.5rem 0',
    width: '100%',
  },
  optionGroupButton: {
    margin: '0.3rem 0.2rem',
  },
  adminButtonGroup: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
    width: '100%',
  },
}));

export const OptionSettingDrawer: FC<Props> = ({ className, optionSettingFormProps, closing }) => {
  const classes = useStyles();
  const adminMenuStore = useContext(AdminMenuContext);

  const { type, selectedSelectables, setNewSelectables, onSaveSelectables, onCancel } = optionSettingFormProps;

  const selectingRequiredOptions = useMemo(
    () => getSelectingRequiredOptions(adminMenuStore.state, selectedSelectables),
    [selectedSelectables]
  );
  const selectingOptionalOptions = useMemo(
    () => getSelectingOptionalOptions(adminMenuStore.state, selectedSelectables),
    [selectedSelectables]
  );

  return (
    <OptionalSettingDrawerWrapper className={className} closing={closing}>
      <div className={classes.section}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="h4">
            {type === 'required' && '必須注文オプション'}
            {type === 'optional' && '任意注文オプション'}
          </Typography>
          <Typography variant="body2" className={classes.instructions}>
            オプションを選択し、【確定】をクリックしてください。
          </Typography>
        </div>
        {type === 'required' && (
          <div className={classes.section}>
            {selectingRequiredOptions.map((selectingRequiredOption) => (
              <OptionGroupButton
                key={`required-optionGroup-${selectingRequiredOption.id}`}
                optionGroup={selectingRequiredOption}
                onClick={setNewSelectables}
                className={classes.optionGroupButton}
              />
            ))}
          </div>
        )}
        {type === 'optional' && (
          <div className={classes.section}>
            {selectingOptionalOptions.map((selectingOptionalOption) => (
              <OptionGroupButton
                key={`optional-optionGroup-${selectingOptionalOption.id}`}
                optionGroup={selectingOptionalOption}
                onClick={setNewSelectables}
                className={classes.optionGroupButton}
              />
            ))}
          </div>
        )}
      </div>
      <div className={classes.section}>
        {type === 'required' && <SelectingOptionsCarousel selectingOptions={selectingRequiredOptions} />}
        {type === 'optional' && <SelectingOptionsCarousel selectingOptions={selectingOptionalOptions} />}
      </div>
      <AdminButtonGroup
        positiveText="確定"
        negativeText="キャンセル"
        onPositiveClick={onSaveSelectables}
        onNegativeClick={onCancel}
        className={classes.adminButtonGroup}
      />
    </OptionalSettingDrawerWrapper>
  );
};
