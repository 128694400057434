import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../../general/props/classname';

type Props = ClassName & {
  closing: boolean;
};

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    height: '100%',
    width: theme.settingsDrawer?.width,
    backgroundColor: theme.settingsDrawer?.background,
    padding: '0 2rem',
    position: 'absolute',
    overflowY: 'auto',
    zIndex: 4,
    right: '0',
    animation: '$slideIn  0.3s ease-out',
    '@media (max-width: 1024px)': {
      animation: '$slideIn1024Under  0.3s ease-out',
    },
    '@media (min-width: 2000px)': {
      width: '44vw',
    },
  },
  '@keyframes slideIn': {
    from: {
      transform: 'translateX(600px)',
    },
    to: {
      transform: 'translateX(0px)',
    },
  },
  '@keyframes slideIn1024Under': {
    from: {
      transform: 'translateX(400px)',
    },
    to: {
      transform: 'translateX(0px)',
    },
  },
  closeAnimation: {
    zIndex: 1,
    animation: '$slideOut  0.6s ease-out',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)': {
      animation: '$slideOut1024Under  0.7s ease-out',
    },
    '@media (min-width: 2000px)': {
      animation: '$slideOut2000Up  0.5s ease-out',
    },
  },
  '@keyframes slideOut': {
    from: {
      transform: 'translateX(0px)',
      zIndex: 4,
    },
    to: {
      transform: 'translateX(650px)',
      zIndex: 1,
    },
  },
  '@keyframes slideOut1024Under': {
    from: {
      transform: 'translateX(0px)',
      zIndex: 4,
    },
    to: {
      transform: 'translateX(450px)',
      zIndex: 1,
    },
  },
  '@keyframes slideOut2000Up': {
    from: {
      transform: 'translateX(0px)',
      zIndex: 4,
    },
    to: {
      transform: 'translateX(900px)',
      zIndex: 1,
    },
  },
}));

/**
 * @param closing trueのときは描画されるがクローズアニメーションが走って消える（右方向に引っ込んでいく）
 */
export const OptionalSettingDrawerWrapper: FC<Props> = ({ className, children, closing }) => {
  const classes = useStyles();

  return <div className={`${classes.drawerWrapper} ${closing && classes.closeAnimation} ${className}`}>{children}</div>;
};
