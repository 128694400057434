import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { DTOOptionGroup } from 'models';
import React, { FC } from 'react';
import { AdminIconButton } from '../parts/adminIconButton';
import { OptionGroupBadge } from '../parts/optionGroupBadge';

interface Props {
  type: 'required' | 'optional';
  optionGroups: DTOOptionGroup[];
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  headline: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
  text: {
    fontSize: '1rem',
    margin: '0.5rem 0',
  },
  iconButton: {
    width: '1rem',
    marginLeft: '2rem',
  },
  optionGroupBadegeGroup: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  optionBadegeItem: {},
}));

export const OptionList: FC<Props> = ({ type, optionGroups, onClick }) => {
  const classes = useStyles();

  const requiredOptionGroups = optionGroups.filter((optionGroup) => optionGroup.minSelectionCount > 0);
  const optionalOptionGroups = optionGroups.filter((optionGroup) => optionGroup.minSelectionCount === 0);

  return (
    <>
      <ButtonBase className={classes.headline} onClick={onClick}>
        <Typography className={classes.text}>
          {type === 'required' && '必須注文オプション'}
          {type === 'optional' && '任意注文オプション'}
        </Typography>
        <AdminIconButton className={classes.iconButton} iconType="edit" onClick={onClick} />
      </ButtonBase>
      {type === 'required' &&
        (requiredOptionGroups.length === 0 ? (
          <Typography variant="body2">設定なし</Typography>
        ) : (
          <div className={classes.optionGroupBadegeGroup}>
            {requiredOptionGroups.map((requiredOptionGroup) => (
              <OptionGroupBadge
                key={`requiredOptionGroup-${requiredOptionGroup.id}`}
                optionGroup={requiredOptionGroup}
                className={classes.optionBadegeItem}
              />
            ))}
          </div>
        ))}
      {type === 'optional' &&
        (optionalOptionGroups.length === 0 ? (
          <Typography variant="body2">設定なし</Typography>
        ) : (
          <div className={classes.optionGroupBadegeGroup}>
            {optionalOptionGroups.map((optionalOptionGroup) => (
              <OptionGroupBadge
                key={`optionalOptionGroup-${optionalOptionGroup.id}`}
                optionGroup={optionalOptionGroup}
                className={classes.optionBadegeItem}
              />
            ))}
          </div>
        ))}
    </>
  );
};
