import { makeStyles } from '@material-ui/core';
import React, { FC, useContext, useState } from 'react';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { DesignContext, designOperations } from '../../../modules/settings/design';
import { UiMessage } from '../../general/parts/uiMessage';
import { CategorySideMenu } from '../units/categorySideMenu';
import { IconSideMenu } from '../units/iconSideMenu';
import { ProductList } from '../units/productList';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: '#ededed',
  },
  iconSideMenu: {
    maxWidth: theme.sideMenu?.width,
  },
  optionSideMenu: {
    minWidth: theme.sideMenu?.width,
    '@media (max-width: 1024px)': {
      minWidth: '13rem',
    },
  },
  content: {},
  testWrapper: {
    width: '100%',
  },
}));

export const MenuLayout: FC = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const adminMenuStore = useContext(AdminMenuContext);
  const designStore = useContext(DesignContext);
  const authStore = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState({ create: false, edit: false });

  useMountEffect(() => {
    authOperations.checkLogin(authStore.dispatch);
    adminMenuOperations.getMenus(uiStore.dispatch, adminMenuStore.dispatch);
    adminMenuOperations.getOptions(uiStore.dispatch, adminMenuStore.dispatch);
    adminMenuOperations.getAllergens(uiStore.dispatch, adminMenuStore.dispatch);
    designOperations.getDesign(uiStore.dispatch, designStore.dispatch);
  });

  const toggleIsOpen = (type: 'create' | 'edit') => {
    setIsOpen((prev) => {
      return { ...prev, [type]: !prev[type] };
    });
  };

  const closeForm = () => {
    setIsOpen({ create: false, edit: false });
  };

  if (!authStore.state.user) return <></>;

  return (
    <div className={classes.wrapper}>
      <IconSideMenu className={classes.iconSideMenu} />
      <CategorySideMenu className={classes.optionSideMenu} closeForm={closeForm} />
      <ProductList className={classes.content} isOpen={isOpen} toggleIsOpen={toggleIsOpen} />
      <UiMessage />
    </div>
  );
};
