import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { AdminBorder } from '../parts/adminBorder';
import { CategoryNameForm } from './categoryNameForm';
import { ProductListHeaderTitle } from './productListHeaderTitle';

type Props = {
  onDeleteCategoryClick: () => void;
  openDndProductList: () => void;
  toggleIsFormOpen: () => void;
  categoryId?: number;
  isSortOpen: boolean;
};

const useStyles = makeStyles((theme) => ({
  productListHeader: {
    width: 'calc(100% - 4rem)',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem auto',
  },
  categoryNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryNameBorder: {
    width: '90%',
  },
  border: {
    width: '100%',
  },
}));

export const ProductListHeader: FC<Props> = ({
  onDeleteCategoryClick,
  openDndProductList,
  toggleIsFormOpen,
  categoryId,
  isSortOpen,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.productListHeader}>
      <div className={classes.categoryNameWrapper}>
        {categoryId && <CategoryNameForm onDeleteCategoryClick={onDeleteCategoryClick} />}
        <FunctionsMenuButton />
      </div>
      <AdminBorder className={classes.categoryNameBorder} />
      <ProductListHeaderTitle
        openDndProductList={openDndProductList}
        toggleIsFormOpen={toggleIsFormOpen}
        isSortOpen={isSortOpen}
      />
      <AdminBorder className={classes.border} />
    </div>
  );
};
