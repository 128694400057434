import { makeStyles } from '@material-ui/core';
import { DTOProduct } from 'models';
import React, { FC, useContext } from 'react';
import { AdminMenuContext } from '../../../modules/settings';
import { AdminIconButton } from '../parts/adminIconButton';

type Props = {
  product: DTOProduct;
  onDelete?: (product: DTOProduct) => void;
  onHide?: (product: DTOProduct) => void;
  onShow?: (product: DTOProduct) => void;
};

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    width: '1.5rem',
    height: '100%',
    margin: '0 0.5rem',
  },
  narrow: {
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)': {
      margin: '0 0.5rem',
    },
  },
}));

export const ProductInfoIconButtons: FC<Props> = ({ product, onDelete, onHide, onShow }) => {
  const classes = useStyles();
  const adminMenuStore = useContext(AdminMenuContext);

  const handleDelete = onDelete
    ? () => {
        onDelete(product);
      }
    : undefined;

  const handleHide = onHide
    ? () => {
        onHide(product);
      }
    : undefined;

  const handleShow = onShow
    ? () => {
        onShow(product);
      }
    : undefined;

  return (
    <div className={classes.iconWrapper}>
      <AdminIconButton
        className={`${classes.iconButton} ${!adminMenuStore.state.toggleFolding && classes.narrow}`}
        iconType="delete"
        onClick={handleDelete}
      />
      {product.isHidden ? (
        <AdminIconButton
          className={`${classes.iconButton} ${!adminMenuStore.state.toggleFolding && classes.narrow}`}
          iconType="invisible"
          onClick={handleShow}
        />
      ) : (
        <AdminIconButton
          className={`${classes.iconButton} ${!adminMenuStore.state.toggleFolding && classes.narrow}`}
          iconType="visible"
          onClick={handleHide}
        />
      )}
    </div>
  );
};
