import { makeStyles, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DTOCategory, DTOProduct } from 'models';
import React, { FC, useContext, useState } from 'react';
import nochoice from '../../../images/settings/edit-menu.svg';
import noImageImg from '../../../images/settings/image-gray.svg';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations, adminSelectors } from '../../../modules/settings';
import { FunctionsMenuButton } from '../../general/parts/functionsMenuButton';
import { ClassName } from '../../general/props/classname';
import { ConfirmDialog } from './confirmDialog';
import { EditProductForm, EditProductFormValues } from './editProductForm';
import { ProductInfoList } from './productInfoList';
import { ProductListHeader } from './productListHeader';

type Props = ClassName & {
  isOpen: { create: boolean; edit: boolean };
  toggleIsOpen: (type: 'create' | 'edit') => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: 'calc((100vw - 18.5rem) * 0.55)',
    backgroundColor: '#ffffff',
    borderRight: '1px solid #cbcbcb',
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 1,
    '@media (max-width: 1024px)': {
      width: 'calc((100vw - 15.5rem) * 0.55)',
    },
  },
  shadow: {
    height: '100%',
    width: '100%',
    backgroundColor: '#00000080',
    position: 'absolute',
    zIndex: 1,
  },
  unselectMessageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  functionsMenuButtonWrapper: {
    margin: '2rem 2rem 0 auto',
  },
  imageContent: {
    width: '40%',
    minWidth: '25rem',
  },
  image: {
    width: '100%',
    opacity: '0.3',
  },
  unselectMessage: {
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
    textAlign: 'center',
    marginTop: '-3rem',
  },
}));

export const ProductList: FC<Props> = ({ className, isOpen, toggleIsOpen }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);
  const uiStore = useContext(UIContext);
  const products = adminSelectors.getSelectingProducts(adminMenuStore.state);

  const [isSortOpen, setIsSortOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(products[0]);

  const toggleIsFormOpen = (type: 'create' | 'edit', product?: DTOProduct) => {
    return () => {
      if (product) {
        setSelectedProduct(product);
      }
      toggleIsOpen(type);
    };
  };

  const onIsDeleteConfirmationOpen = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const onIsDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const selectCategory: DTOCategory | undefined = adminMenuStore.state.menus
    .flatMap((menu) => menu.categories.map((category: DTOCategory) => category))
    .find((category) => category.id === adminMenuStore.state.selectingCategoryId);

  const onDeleteCategoryClick = async () => {
    if (adminMenuStore.state.selectingCategoryId) {
      await adminMenuOperations.deleteCategory(uiStore.dispatch, adminMenuStore.dispatch, {
        categoryId: adminMenuStore.state.selectingCategoryId,
      });
    }
    onIsDeleteConfirmationClose();
  };

  const openDndProductList = () => {
    setIsSortOpen(!isSortOpen);
  };

  const createProduct = async (values: EditProductFormValues) => {
    if (!adminMenuStore.state.selectingCategoryId) {
      return;
    }

    await adminMenuOperations.createProduct(
      uiStore.dispatch,
      adminMenuStore.dispatch,
      {
        categoryId: adminMenuStore.state.selectingCategoryId,
        name: values.name,
        price: parseInt(values.price, 10),
        description: values.description,
        allergens: values.allergens,
        selectables: values.selectables,
        takeoutEnabled: values.takeoutEnabled,
      },
      values.image
    );

    toggleIsFormOpen('create')();
  };

  const updateProduct = async (values: EditProductFormValues) => {
    await adminMenuOperations.updateProduct(
      uiStore.dispatch,
      adminMenuStore.dispatch,
      {
        ...selectedProduct,
        name: values.name,
        price: parseInt(values.price, 10),
        description: values.description,
        allergens: values.allergens,
        selectables: values.selectables,
        takeoutEnabled: values.takeoutEnabled,
      },
      values.image
    );
    toggleIsFormOpen('edit')();
  };

  const updateSequence = async (id: number, sequence: { sequence: number }) => {
    await adminMenuOperations.updateProductSequence(uiStore.dispatch, adminMenuStore.dispatch, id, sequence);
  };

  return adminMenuStore.state.selectingCategoryId ? (
    <>
      <div className={`${classes.wrapper} ${className}`}>
        {isOpen.create && <div className={classes.shadow} onClick={toggleIsFormOpen('create')} aria-hidden />}
        {isOpen.edit && <div className={classes.shadow} onClick={toggleIsFormOpen('edit')} aria-hidden />}
        <ProductListHeader
          categoryId={adminMenuStore.state.selectingCategoryId}
          onDeleteCategoryClick={onIsDeleteConfirmationOpen}
          openDndProductList={openDndProductList}
          toggleIsFormOpen={toggleIsFormOpen('create')}
          isSortOpen={isSortOpen}
        />
        <ConfirmDialog
          open={isDeleteConfirmationOpen}
          messages={selectCategory ? [`${selectCategory.name}を削除します。`] : []}
          onClose={onIsDeleteConfirmationClose}
          onConfirm={onDeleteCategoryClick}
        />
        <ProductInfoList
          products={products}
          isSortOpen={isSortOpen}
          toggleIsFormOpen={toggleIsFormOpen}
          updateSequence={updateSequence}
        />
      </div>
      {isOpen.create && (
        <EditProductForm
          defaults={{
            name: '',
            price: '',
            description: '',
            allergens: [],
            selectables: [],
            takeoutEnabled: false,
            image: { hasImage: false, data: noImageImg },
          }}
          onSave={createProduct}
          onCancel={toggleIsFormOpen('create')}
        />
      )}
      {isOpen.edit && (
        <EditProductForm
          defaults={{
            name: selectedProduct.name,
            price: selectedProduct.price.toString(),
            description: selectedProduct.description,
            allergens: selectedProduct.allergens,
            selectables: selectedProduct.selectables,
            takeoutEnabled: selectedProduct.takeoutEnabled,
            soldoutEndDate: selectedProduct.soldoutEndDate,
            image: {
              hasImage: selectedProduct.hasImage,
              data: selectedProduct.hasImage
                ? `/images/products/${selectedProduct.id}.png?date=${format(
                    new Date(selectedProduct.updatedAt),
                    'yyyyMMddHHmmss'
                  )}`
                : noImageImg,
            },
          }}
          onSave={updateProduct}
          onCancel={toggleIsFormOpen('edit')}
        />
      )}
    </>
  ) : (
    <div className={`${classes.unselectMessageWrapper} ${className}`}>
      <FunctionsMenuButton className={classes.functionsMenuButtonWrapper} />
      <div className={classes.imageContent}>
        <img src={nochoice} alt="商品カテゴリを選んでください。" className={classes.image} />
        <Typography variant="body1" className={classes.unselectMessage}>
          商品カテゴリーを、 左メニューより選択してください。
        </Typography>
      </div>
    </div>
  );
};
