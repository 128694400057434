import { DTOProduct } from 'models';
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ProductInfo } from './productInfo';

type Props = {
  product: DTOProduct;
  index: number;
  toggleIsFormOpen: (type: 'create' | 'edit', product?: DTOProduct | undefined) => () => void;
};

export const DraggableProduct: FC<Props> = ({ product, index, toggleIsFormOpen }) => {
  return (
    <Draggable draggableId={`${product.id}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
        >
          <ProductInfo product={product} onClick={toggleIsFormOpen('edit', product)} sorting />
        </div>
      )}
    </Draggable>
  );
};
