import { makeStyles } from '@material-ui/core';
import { DTOMenu } from 'models';
import React, { FC } from 'react';
import { AddCategoryButton } from './addCategoryButton';
import { DroppableCategory } from './droppableCategory';

type Props = {
  menu: DTOMenu;
  onCategoryClick: (categoryId: number) => void;
  updateSequence: (categoryId: number, sequence: number, menuId: number) => void;
  onOpen: () => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {},
}));

export const CategoryList: FC<Props> = ({ menu, onCategoryClick, updateSequence, onOpen }) => {
  const classes = useStyles();

  const onClickFactory = (categoryId: number) => {
    return () => onCategoryClick(categoryId);
  };

  return (
    <div className={classes.wrapper}>
      <DroppableCategory
        categories={menu.categories}
        menuId={menu.id}
        updateSequence={updateSequence}
        onClickFactory={onClickFactory}
      />
      <AddCategoryButton onClick={onOpen} />
    </div>
  );
};
