import { FormControlLabel, makeStyles, Switch, withStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import React, { FC } from 'react';

interface Props {
  takeoutEnabled: boolean;
  onChange: (takeoutEnabled: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: '1.5rem',
    marginLeft: 0,
  },
}));

const GreenSwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: green[300],
    },
    '&$checked + $track': {
      backgroundColor: green[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export const TakeoutToggleButton: FC<Props> = ({ takeoutEnabled, onChange }) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  return (
    <>
      <FormControlLabel
        control={<GreenSwitch checked={takeoutEnabled} onChange={handleChange} />}
        label="テイクアウト可能商品"
        labelPlacement="start"
        className={classes.wrapper}
      />
    </>
  );
};
